import { Component, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { User } from '@xpo-ltl/sdk-common';
import { XpoLtlGlobalEnsembleFilterService } from '@xpo-ltl/symphony-ensemble-ui';
import { XpoAccountPopoverConfig, XpoAppSwitcherApplication, XpoShellRoute } from '@xpo/ngx-core';
import { XpoFilter } from '@xpo/ngx-core-board';
import { Observable } from 'rxjs';
import { delay, retryWhen, take } from 'rxjs/operators';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { XpoLtlAppSwitcherService } from './shared/services/app-switcher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title: string;
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  filters: XpoFilter[];

  constructor(
    private configManagerService: ConfigManagerService,
    private loginService: LoginService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private globalFilterService: XpoLtlGlobalEnsembleFilterService
  ) {
    this.routes = [
      {
        label: 'Symphony Ensembles',
        path: AppRoutes.SYMPHONY_ENSEMBLES,
      },
    ];

    this.filters = globalFilterService.setupRefreshIntervalFilter();

    /** Shell setup */
    this.title = `Symphony`;
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    /** Initialize SIC switcher by getting logged in user's sic */
    this.handleLoggedInUser();
    this.loginService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  private handleLoggedInUser(): void {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            delay(1000),
            take(5)
          )
        )
      )
      .subscribe(
        (user: User) => {
          if (user) {
            this.populateAccountPopover(user);
            this.populateAppSwitcher();
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loginService.clear();
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  onGlobalFilterChange($event): void {
    this.globalFilterService.publishRefreshIntervalChange($event.refreshInterval);
  }
}
