import { Injectable } from '@angular/core';
import { AppMetadataApiService, ListApplicationsResp } from '@xpo-ltl/sdk-appmetadata';
import { XpoAppSwitcherApplication } from '@xpo/ngx-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class XpoLtlAppSwitcherService {
  constructor(private appMetadataApi: AppMetadataApiService) {}

  getAppList(): Observable<XpoAppSwitcherApplication[]> {
    return this.appMetadataApi.listApplications().pipe(
      map((resp: ListApplicationsResp) => {
        return resp.applications.map((app) => {
          const xpoApp: XpoAppSwitcherApplication = {} as XpoAppSwitcherApplication;
          xpoApp.name = app.appName;
          xpoApp.url = app.url;
          return xpoApp;
        });
      })
    );
  }
}
