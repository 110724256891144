import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';

export const RouterDefinitions: Routes = [
  {
    path: AppRoutes.SYMPHONY_ENSEMBLES,
    // loadChildren: () => import('@xpo-ltl/symphony-ensemble-ui').then((m) => m.SymphonyEnsemblesModule),
    loadChildren: () => import('./symphony-ensembles/pnd-ensembles.module').then((m) => m.PndEnsemblesModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: '',
    redirectTo: AppRoutes.SYMPHONY_ENSEMBLES,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { enableTracing: false })],
  exports: [RouterModule, CommonModule],
})
export class AppRoutingModule {}
